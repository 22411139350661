import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const workflowReducer = createReducer(initialState.workflow, {
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.workflow,
  [types.WORKFLOW_LOAD_LIST_COMPLETED]: (state, action) => ({
    ...state,
    list: action.data,
  }),
  [types.WORKFLOW_SET_FILTER]: (state, { filter }) => ({
    ...state,
    filter,
  }),
  [types.WORKFLOW_SET_FILTER_CONTEXT]: (state, { filter }) => ({
    ...state,
    filterContext: filter,
  }),
  [types.WORKFLOW_SET_LOADING]: (state, { value }) => ({
    ...state,
    loading: value,
  }),
  [types.WORKFLOW_GET_WORKFLOW_NUMBER_COMPLETED]: (state, { data }) => ({
    ...state,
    number: data,
  }),
})

export default workflowReducer
