import React, { useState } from 'react'
import moment from 'moment'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker, Day } from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'
import withTranslate from 'Root/app/withTranslate'
import TextField from '@material-ui/core/TextField'

const styles = (theme) => ({
  input: {
    paddingTop: 3,
  },
  root: {
    height: 29,
  },
  week: {
    borderRightColor: theme.palette.divider,
    borderRightStyle: 'solid',
    color: 'red',
    fontSize: '0.6em',
    marginLeft: -10,
    marginTop: 12,
    paddingRight: 5,
    position: 'absolute',
  },
})

const DateField = ({
  classes,
  input,
  label,
  meta: { touched, error },
  t,
  getEntityCaption,
  dateFormat,
  weekdays,
  valueNoUnderline,
  view,
  ...other
}) => {
  const { value, onChange, name } = input
  const [isFocused, setIsFocused] = useState(false)
  const handleChange = (e) => {
    onChange(e)
  }

  if (!view) {
    view = 'day'
  }
  let views
  switch (view) {
    case 'day':
      views = ['year', 'date']
      dateFormat = 'DD-MM-YYYY'
      break
    case 'month':
      views = ['year', 'month']
      dateFormat = 'MM-YYYY'
      break
    case 'year':
      views = ['year']
      dateFormat = 'YYYY'
      break
  }

  // eslint-disable-next-line react/display-name
  const renderDay = () => (date, selectedDate, dayInCurrentMonth) =>
    (
      <div>
        {date.day() === 1 && (
          <div className={classes.week}>{date.isoWeek()}</div>
        )}
        <Day
          current={date.isSame(moment(), 'day')}
          hidden={!dayInCurrentMonth}
          selected={date.isSame(selectedDate, 'day')}
        >
          {date.date()}
        </Day>
      </div>
    )
  return (
    <>
      <KeyboardDatePicker
        error={touched && !!error}
        autoOk
        name={name}
        fullWidth
        InputProps={{ classes: { input: classes.input, root: classes.root } }}
        label={label}
        value={value || null}
        onChange={handleChange}
        minDateMessage={t('common.form.dateField.minDateMessage', {
          value: other.disablePast
            ? moment().format(dateFormat)
            : other.minDate
            ? moment(other.minDate).format(dateFormat)
            : '',
        })}
        maxDateMessage={t('common.form.dateField.maxDateMessage', {
          value: other.disableFuture
            ? moment().format(dateFormat)
            : other.maxDate
            ? moment(other.maxDate).format(dateFormat)
            : '',
        })}
        invalidLabel=""
        invalidDateMessage={
          value ? t('common.form.dateField.invalidDateMessage') : ''
        }
        cancelLabel={t('common.form.dateField.cancelLabel')}
        clearLabel={t('common.form.dateField.clearLabel')}
        format={dateFormat}
        FormHelperTextProps={{ error: true }}
        renderDay={weekdays && renderDay()}
        views={views}
        TextFieldComponent={(props) => (
          <TextField
            {...props}
            InputProps={{
              ...props.InputProps,
              disableUnderline:
                valueNoUnderline && value && !isFocused ? true : false,
            }}
            variant="standard"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        )}
        {...other}
      />
      <FormHelperText error>{error}</FormHelperText>
    </>
  )
}

export default compose(withTranslate, withStyles(styles))(DateField)
