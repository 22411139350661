import { call, put, delay } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { show } from 'redux-modal'
import { confirmSaga, globalError } from './shell'
import { showMessage } from 'Actions/shell'
import {
  getWorkflowNumberCompleted,
  loadList,
  loadListCompleted,
  loadListManual,
  setLoading,
} from 'Actions/workflow'
import { t } from 'Root/app/IntlProvider'
import { confirm } from 'Actions/shell'
import { messageLevel } from 'Utils/constants'

export function* checkHtmlSaga(action) {
  const { payload } = action
  const { field, html, resolve } = payload
  const { ok, error, data } = yield http.post('/workflows/check-html', {
    html,
  })
  if (ok) {
    if (data.error) {
      yield put(
        confirm(
          t('workflow.template.message.title', { field }),
          t('workflow.template.message.content', {
            message: data.content,
          }),
          {
            cancelVisible: false,
            noVisible: false,
            yesCaption: t('workflow.template.message.ok'),
          }
        )
      )
    }
    yield call(resolve, !data.error)
  } else if (error) {
    yield call(globalError, data)
    yield call(resolve, false)
  }
}

export function* createWorkflowSaga(action) {
  const { payload } = action
  const { created, error, data } = yield http.post('/workflows', payload)
  if (created) {
    yield put(loadList())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* deleteWorkflowSaga(action) {
  const { id, name } = action
  const yes = yield call(confirmSaga, {
    title: t('workflow.message.delete.title'),
    content: t('workflow.message.delete.content', {
      name,
    }),
  })
  if (!yes) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/workflows/${id}`)
  if (noContent) {
    yield put(loadList())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getActionsSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.get(`/workflows/${id}/actions`)
  if (ok) {
    yield put(show('workflowSummary', { data }))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getWorkflowSaga(action) {
  const { id, step } = action.payload
  if (id) {
    const { ok, error, data } = yield http.get(`/workflows/${id}`)
    if (ok) {
      yield put(show('workflow', { data, step }))
    } else if (error) {
      yield call(globalError, data)
    }
  } else {
    yield put(show('workflow', { ...action.payload, isNew: true }))
  }
}

export function* getWorkflowNumberSaga() {
  const { ok, error, data } = yield http.get(`/workflows/number`)
  if (ok) {
    yield put(getWorkflowNumberCompleted(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* loadListSaga() {
  yield put(setLoading(true))
  const { ok, error, data } = yield http.get('/workflows')
  if (ok) {
    yield put(loadListCompleted(data))
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setLoading(false))
}

export function* loadListManualSaga(action) {
  const { entity, id } = action
  const { ok, error, data } = yield http.get(`/workflows/${entity}/${id}`)
  if (ok) {
    yield put(loadListCompleted(data))
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setLoading(true))
}

export function* startWorkflowSaga(action) {
  const { payload } = action
  const { entity, entityId, workflowId, resolve, reject } = payload
  const { ok, error, data, notFound } = yield http.post('/workflows/start', {
    context: entity,
    entityId,
    id: workflowId,
  })
  if (ok) {
    yield delay(500)
    yield put(loadListCompleted(data))
    yield call(resolve)
  } else if (notFound) {
    yield put(
      showMessage(t('workflow.message.notFound.title'), messageLevel.warning)
    )
    yield call(reject)
  } else if (error) {
    yield call(globalError, data)
    yield call(reject)
  }
}

export function* updateWorkflowSaga(action) {
  const { payload } = action
  const { id, ...rest } = payload
  const { ok, error, data } = yield http.put(`/workflows/${id}`, rest)
  if (ok) {
    yield put(loadList())
  } else if (error) {
    yield call(globalError, data)
  }
}
