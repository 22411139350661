import { call, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import { getList } from 'Actions/automation'
import { get as getEntity, getEmails, setPartialData } from 'Actions/entity'
import { getPlacements } from 'Actions/job'
import { getAccountStatus } from 'Actions/auth'
import { getRange } from 'Actions/event'
import { getMy } from 'Actions/user'
import { loadMyTasks } from 'Actions/task'

import {
  getNew,
  getNewSucceed,
  getSubscriptions,
  getSubscriptionsSucceed,
  loadAll,
  loadAllSucceed,
  setLoading,
} from 'Actions/notification'
import { load } from 'Actions/table'
import { showMessage } from 'Actions/shell'
import { confirmSaga } from './shell'
import { messageLevel } from 'Utils/constants'
import { t } from 'Root/app/IntlProvider'

export function* getNewSaga() {
  const { ok, error, data } = yield http.get('/notifications/new')
  if (ok) {
    yield put(getNewSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getSubscriptionsSaga(action) {
  const { role = '' } = action
  const { ok, error, data } = yield http.get(
    `/notifications/subscriptions/${role}`
  )
  if (ok) {
    yield put(getSubscriptionsSucceed(data, role))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* loadAllSaga() {
  yield put(setLoading(true))
  const { ok, error, data } = yield http.get('/notifications')
  if (ok) {
    yield put(loadAllSucceed(data))
    yield put(getNew())
  } else if (error) {
    yield call(globalError, data)
  }
  yield put(setLoading(false))
}

export function* receiveMessageSaga({ payload }) {
  const { type } = payload
  console.log(payload)
  const state = yield select()
  const pathname = window.location.pathname
  switch (type) {
    case 'fileUpload': {
      const { id, data } = payload
      yield put(setPartialData(id, data))
      break
    }
    case 'refreshAutomation': {
      const { url } = payload
      if (pathname.startsWith(url)) {
        yield put(getList())
      }
      break
    }
    case 'refreshCalendar': {
      const { url } = payload
      if (pathname.startsWith(url)) {
        yield put(getRange())
      }
      break
    }
    case 'refreshCandidates':
    case 'refreshRelations': {
      const { url } = payload
      if (url === pathname) {
        yield put(load(0, true))
      }
      break
    }
    case 'refreshEntity': {
      const { entityId, url } = payload
      if (pathname.startsWith(url) && !state.entity.info.editMode) {
        yield put(getEntity(entityId))
      }
      break
    }
    case 'refreshJobBoard': {
      const { jobId, url } = payload
      if (pathname.startsWith(url)) {
        yield put(getPlacements(jobId))
      }
      break
    }
    case 'refreshMyMail': {
      const { urls } = payload
      if (pathname.startsWith('/emails')) {
        yield put(load(0, true))
      } else {
        for (const url of urls) {
          if (pathname.startsWith(url)) {
            const parts = url.split('/')
            yield put(getEmails(parts[2]))
          }
        }
      }
      break
    }
    case 'refreshMyTasks':
      yield put(loadMyTasks())
      break
    case 'refreshNylasStatus':
      {
        const { accountId, url } = payload
        if (pathname.startsWith(url)) {
          yield put(getAccountStatus(accountId))
        }
        yield put(getMy())
      }
      break
  }
}

export function* removeReadSaga() {
  const yes = yield call(confirmSaga, {
    title: t('notification.message.remove.title'),
    content: t('notification.message.remove.content'),
  })
  if (!yes) {
    return
  }
  const { noContent, error, data } = yield http.delete(`/notifications/read`)
  if (noContent) {
    yield put(loadAll())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* setReadAllSaga() {
  const yes = yield call(confirmSaga, {
    title: t('notification.message.read.title'),
    content: t('notification.message.read.content'),
  })
  if (!yes) {
    return
  }
  const { ok, error, data } = yield http.post(`/notifications/read`)
  if (ok) {
    yield put(loadAll())
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* setReadSaga(action) {
  const { id } = action
  const { ok, error, data } = yield http.put(`/notifications/${id}/read`)
  if (ok) {
    // do nothing
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* updateSubscriptionSaga(action) {
  const { payload } = action
  const { message, ...values } = payload
  const state = yield select()
  const role = state.notification.subscription.role
  const { ok, error, data } = yield http.put(
    `/notifications/subscriptions/${role}`,
    values
  )
  if (ok) {
    if (message) {
      yield put(getSubscriptions())
      const type = t(`lookup.notificationType.${values.notificationType}`)
      yield put(
        showMessage(
          t('auth.profile.tabs.notifications.message.unsubscribe', { type }),
          messageLevel.info
        )
      )
    }
  } else if (error) {
    yield call(globalError, data)
  }
}
