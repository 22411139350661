export const getLookupInfo = (list, value) => {
  try {
    if (list && list[value]) {
      return list[value].info
    }
  } catch (e) {}
  return ''
}

export const getLookupName = (list, value) => {
  try {
    if (list && list[value]) {
      return list[value].name
    }
  } catch (e) {}
  return ''
}

export const getLookupObject = (list, value) => {
  if (value && list && list[value]) {
    return {
      ...list[value],
    }
  } else {
    return null
  }
}

export const getLookupIconObject = (list, value) => {
  if (list && list[value]) {
    return {
      color: list[value].color,
      icon: list[value].icon,
    }
  } else {
    return null
  }
}

export const getNewPositions = (list, previousIndex, newIndex) => {
  let array = list
  if (!Array.isArray(array)) {
    array = toArray(list).slice(0)
  } else {
    array = array.slice(0)
  }
  if (newIndex >= list.length) {
    let k = newIndex - array.length
    while (k-- + 1) {
      array.push(undefined)
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0])
  return array.map((x) => x.id)
}

export const getFullUserName = (user, sourcerGroups) => {
  if (user.sourcerGroup) {
    const group = (sourcerGroups || {})[user.sourcerGroup]
    if (group && group.name) {
      return `${user.name} [${group.name}]`
    }
  }
  return user.name
}

export const hasIcons = (list) => {
  if (list) {
    return toArray(list).some((item) => item.icon)
  } else {
    return false
  }
}

export const toArray = (items = {}, noSort = false) => {
  let result = []
  if (!items) {
    return result
  }
  if (Array.isArray(items)) {
    result = items.map((item) => {
      const { name, order, ...rest } = item
      return {
        description: name,
        order: 0,
        ...rest,
      }
    })
  } else {
    try {
      result = Object.entries(items).map((entry) => {
        const { name, ...rest } = entry[1]
        return {
          id: entry[0],
          description: name,
          ...rest,
        }
      })
    } catch (e) {
      return []
    }
  }
  if (noSort) {
    return result
  }
  return result.sort((a, b) => {
    if (a.order === 0 && b.order === 0) {
      if (a.description < b.description) return -1
      if (a.description > b.description) return 1
      return 0
    } else {
      if (a.order < b.order) return -1
      if (a.order > b.order) return 1
      return 0
    }
  })
}

export const toItemsArray = (items = {}) => {
  let result = []
  if (!items) {
    return result
  }
  if (Array.isArray(items)) {
    result = items.map((item) => {
      const { name, order, ...rest } = item
      return {
        description: name,
        order: 0,
        ...rest,
      }
    })
  } else {
    result = Object.entries(items).map((entry) => {
      const { name, ...rest } = entry[1]
      return {
        id: entry[0],
        description: name,
        ...rest,
      }
    })
  }
  const sort = result.sort((a, b) => {
    if (a.order === 0 && b.order === 0) {
      if (a.description < b.description) return -1
      if (a.description > b.description) return 1
      return 0
    } else {
      if (a.order < b.order) return -1
      if (a.order > b.order) return 1
      return 0
    }
  })
  return sort.map(({ icon, color, description }) => ({
    icon,
    color,
    description,
  }))
}

export const toObjects = (items = []) => {
  const result = {}
  for (const item of items) {
    result[item.id] = {
      id: item.id,
      entity: item.entity,
      name: item.name,
      order: item.order || 0,
      system: false,
      icon: item.icon,
      color: item.color,
      language: item.language,
      options: item.options,
      mutation: {
        deleted: item.deleted,
      },
    }
  }
  return result
}

export const toTemplateObjects = (
  items = [],
  prefixLanguage = true,
  language,
  onlyVisible = true
) => {
  const result = {}
  for (const item of items) {
    for (const locale of item.locale) {
      const id = prefixLanguage ? `${locale.language}-${item.id}` : item.id
      if (!prefixLanguage && locale.language !== language) {
        continue
      }
      result[id] = {
        id,
        entity: item.entity,
        name: locale.name,
        order: item.order || 0,
        system: false,
        language: locale.language,
        mutation: {
          deleted: onlyVisible ? !item.visible : false,
        },
        child: locale.language,
      }
    }
  }
  return result
}

export const toAssignedToObjects = (users, teams) => {
  const result = {}
  let index = 0
  for (const user of users) {
    index++
    result[user.id] = {
      color: user.color,
      id: user.id,
      email: user.email,
      entity: 'user',
      name: user.name,
      order: index,
      icon: user.icon,
      mutation: {
        deleted: user.deleted || (user.mutation || {}).deleted || false,
      },
    }
  }
  for (const key in teams) {
    index++
    result[key] = {
      ...teams[key],
      entity: 'team',
      icon: 'AccountGroup',
      order: index,
    }
  }
  return result
}
