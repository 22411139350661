import { createSelector } from 'reselect'
import { role } from 'Utils/constants'
import moment from 'moment'
import { getFullUserName, getLookupName } from 'Utils/lookup'

const getCompanies = (state, activated) =>
  (state.cache.entities.companies || []).filter(
    (company) => company.deleted === !activated
  )
const getRole = (state) => state.auth.user.role
const getSessions = (state) => state.auth.sessions || []
const getDemo = (state) => state.auth.info.demo
const getUsers = (state, activated) =>
  (state.cache.entities.users || []).filter(
    (user) => user.deleted === !activated
  )
const getViewers = (state) => state.cache.entities.viewers
const getCompanyTypes = (state) => state.cache.lookups.companyType
const getUserLanguage = (state) => state.auth.user.language
const getLanguages = (state) => state.cache.lookups.language
const getSourcesList = (state) => state.auth.sources.list
const getSourcesFilter = (state) => state.auth.sources.filter
const getTagsList = (state) => state.auth.tags.list
const getTagsFilter = (state) => state.auth.tags.filter
const getUserList = (state) => state.cache.entities.users || []
const getUserFilter = (state) => state.cache.entities.userFilter
const getUserFilterType = (state) => state.cache.entities.userFilterType
const getCompanyList = (state) => state.cache.entities.companies || []
const getCompanyFilter = (state) => state.cache.entities.companyFilter
const getTeams = (state) => state.cache.lookups.team
const getRoles = (state) => state.cache.lookups.role
const getSourcerGroups = (state) => state.cache.lookups.sourcerGroup
const getNoEmail = (state) => !!state.auth.user.noEmail
const getShares = (state, userMode) =>
  userMode ? state.candidate.shares.profile : state.candidate.shares.company
const getPlugin = (state, plugin) => state.auth.tenant.plugins[plugin]

export const hasRoleSystem = createSelector(getRole, (x) => x === role.system)
export const hasRoleAdmin = createSelector(
  getRole,
  (x) => x === role.admin || x === role.system
)
export const hasRoleUser = createSelector(
  getRole,
  (x) => x === role.user || x === role.admin || x === role.system
)
export const isRoleHiring = createSelector(getRole, (x) => x === role.hiring)
export const isRoleSourcer = createSelector(getRole, (x) => x === role.sourcer)
export const isDemo = createSelector(getDemo, (x) => x)

export const canSendEmail = createSelector(getNoEmail, (x) => !x)

const getCompanyString = (list, companies) => {
  if (list && list.length > 0) {
    return (companies || [])
      .map((company) => {
        const find = (list.find((x) => x.id === company) || {}).name
        return find || company
      })
      .sort()
      .join(', ')
  }
  return ''
}

const getUserString = (list, users) => {
  if (list && list.length > 0) {
    return (users || [])
      .map((user) => {
        const find = list.find((x) => x.id === user) || {}
        return {
          name: find.name,
          deleted: typeof find.deleted === 'undefined' ? true : find.deleted,
        }
      })
      .filter((x) => !x.deleted)
      .map((x) => x.name)
      .sort()
      .join(', ')
  }
  return ''
}

const getTeamList = (teamList, teams) => {
  if (teamList) {
    return (teams || [])
      .filter((team) => teamList[team])
      .map((team) => teamList[team].name)
      .join(', ')
  }
  return ''
}

export const companyList = createSelector(
  [getCompanies, getUserList, getCompanyFilter, getCompanyTypes],
  (companies, users, filter, types) =>
    companies
      .map((company) => {
        return {
          id: company.id,
          name: company.name,
          type: getLookupName(types, company.type),
          users: getUserString(users, company.users),
          deleted: company.deleted,
        }
      })
      .filter((x) => {
        if (!filter) {
          return true
        }
        return x.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      })
)

export const companyActivated = createSelector(
  [getCompanyList],
  (companies) => {
    return {
      activated: companies.filter((company) => !company.deleted).length,
      deactivated: companies.filter((company) => company.deleted).length,
    }
  }
)

export const sessionList = createSelector(
  [getSessions, getUserFilter, getUserFilterType],
  (sessions, filter, filterType) =>
    sessions.filter((user) => {
      let filtered = false,
        filteredType = false
      if (
        filter &&
        (user.name || '').toLowerCase().indexOf(filter.toLowerCase()) !== -1
      ) {
        filtered = true
      }
      if (filterType && user.role === filterType) {
        filteredType = true
      }
      return !!filter === filtered && !!filterType === filteredType
    })
)

export const sourceList = createSelector(
  [getSourcesList, getSourcesFilter],
  (list, filter) =>
    list.filter((x) => {
      if (!filter) {
        return true
      }
      return x.source.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    })
)

export const tagList = createSelector(
  [getTagsList, getTagsFilter],
  (list, filter) =>
    list.filter((x) => {
      if (!filter) {
        return true
      }
      return x.tag.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    })
)

export const userList = createSelector(
  [
    getUsers,
    getCompanyList,
    getTeams,
    getRoles,
    getUserFilter,
    getUserFilterType,
    getSourcerGroups,
  ],
  (users, companies, teams, roles, filter, filterType, sourcerGroups) =>
    users
      .filter((user) => {
        let filtered = false,
          filteredType = false
        if (
          filter &&
          (user.name || '').toLowerCase().indexOf(filter.toLowerCase()) !== -1
        ) {
          filtered = true
        }
        if (filterType && user.role === filterType) {
          filteredType = true
        }
        if (user.role == 'viewer') {
          return false
        }
        return !!filter === filtered && !!filterType === filteredType
      })
      .map((user) => {
        return {
          color: user.color,
          icon: user.icon,
          id: user.id,
          '2fa': user['2fa'],
          name: getFullUserName(user, sourcerGroups),
          email: user.email,
          endsAt: user.endsAt
            ? moment(user.endsAt).format('D-M-YYYY HH:mm')
            : '',
          noEmail: user.noEmail,
          role: roles && user.role ? roles[user.role].name : '',
          teams: getTeamList(teams, user.teams),
          companies: getCompanyString(companies, user.companies),
          lastSignin: user.lastSignin
            ? moment(user.lastSignin).format('D-M-YYYY HH:mm')
            : '',
          deleted: user.deleted,
        }
      })
)

export const userActivated = createSelector(
  [getUserList, getSessions, getViewers],
  (users, sessions, viewers) => {
    return {
      activated: users.filter((user) => !user.deleted && user.role !== 'viewer')
        .length,
      deactivated: users.filter(
        (user) => user.deleted && user.role !== 'viewer'
      ).length,
      session: sessions.length,
      viewers: viewers.length,
    }
  }
)

export const viewerList = createSelector(
  [getViewers, getUserFilter],
  (viewers, filter) =>
    viewers
      .filter((viewer) => {
        if (!filter) {
          return true
        }
        return (
          (viewer.name || '').toLowerCase().indexOf(filter.toLowerCase()) !== -1
        )
      })
      .map((viewer) => {
        return {
          color: '#bdc3c7',
          email: viewer.email,
          icon: 'AccountEye',
          id: viewer.id,
          language: viewer.language,
          name: viewer.name,
          inUse: viewer.inUse,
        }
      })
)

export const getSpeechLanguage = createSelector(
  [getUserLanguage, getLanguages],
  (language, languages) => {
    if (language && languages && languages[language]) {
      return languages[language].speech
    }
    return 'nl-NL'
  }
)

export const getShareData = createSelector([getShares], (shares) => {
  const list = shares.map((share) => ({
    candidates:
      (share.candidates || [])
        .slice(0, 5)
        .map((x) => x.name)
        .join(', ') +
      ((share.candidates || []).length > 5
        ? `... (+${share.candidates.length - 5})`
        : ''),
    createdAt: share.createdAt,
    date: share.expiresAt,
    id: share.id,
    job: (share.job || {}).name,
    user: (share.user || {}).name,
    viewers: (share.viewers || []).map((x) => x.name).join(', '),
    views: share.views,
  }))
  return {
    list,
    shares,
  }
})

export const isPluginAvailable = createSelector([getPlugin], (plugin) => {
  if (!plugin) {
    return false
  }
  return plugin.available
})

export const plugins = {
  bm: 'bm',
  branch: 'branch',
  coc: 'coc',
  companies: 'companies',
  connect: 'connect',
  crm: 'crm',
  document: 'document',
  export: 'export',
  funnel: 'funnel',
  hiringmanager: 'hiringmanager',
  linkedIn: 'linkedIn',
  multiboard: 'multiboard',
  multilang: 'multilang',
  nylas: 'nylas',
  pipelines: 'pipelines',
  project: 'project',
  resumeParsing: 'resumeParsing',
  robots: 'robots',
  sourcer: 'sourcer',
  sso: 'sso',
  viewer: 'viewer',
  webhook: 'webhook',
  websites: 'websites',
  whatsapp: 'whatsapp',
  workflow: 'workflow',
  workflows: 'workflows',
}
