import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { show } from 'redux-modal'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ClearIcon from '@material-ui/icons/Clear'
import { t } from 'Root/app/IntlProvider'
import Tooltip from 'Components/common/Tooltip'
import { gotoUrl } from 'Actions/app'

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  input: {
    cursor: 'pointer',
  },
}))

const SearchField = ({
  caption = '',
  disableUnderline,
  disabled,
  emptyValue = false,
  filters,
  entity,
  formTitle,
  fullWidth = true,
  hyperlink,
  icon,
  id,
  input,
  inputProps,
  items,
  label,
  list,
  lookupMode = false,
  margin = 'dense',
  maxItems,
  meta,
  multiple,
  noClear = false,
  nolabel,
  onChange,
  onChangeName,
  onChangeItem,
  onIconClick,
  placeholder,
  readOnly,
  required,
  sortable = false,
  valueNoUnderline,
}) => {
  const classes = useStyles()
  const [name, setName] = useState(caption)
  const dispatch = useDispatch()
  const isLookup = !!items

  const { dirty, error, active } = meta || {}
  useEffect(() => setName(caption || ''), [caption, id])
  useEffect(() => {
    if (isLookup) {
      // Lookupfield
      const values = Array.isArray(input.value) ? input.value : [input.value]
      const names = []
      for (const value of values) {
        const item = items.find((x) => x.id === value)
        if (item) {
          names.push(item.description)
        }
      }
      if (!caption) {
        setName(names.join(', '))
      }
    }
  }, [input, items, isLookup, caption])

  const handleSearchClick = (event) => {
    if (event) {
      event.stopPropagation()
    }
    dispatch(
      show('searchForm', {
        entity,
        items,
        filters,
        lookupMode,
        lookupList: list,
        maxItems,
        multiple,
        onClick: handleClick,
        selected: multiple ? input.value : null,
        sortable,
        title: isLookup
          ? t('common.form.selectField.select.title', {
              list: (label || '').toLowerCase(),
            })
          : t(`common.searchForm.title.${formTitle}`),
        values: Array.isArray(input.value) ? input.value : [input.value],
      })
    )
  }

  const handleInputClick = () => {
    if (hyperlink && input.value) {
      dispatch(gotoUrl({ url: `/${entity}s/${input.value}` }))
    } else if (!disabled) {
      handleSearchClick()
    }
  }
  const handleClearClick = (e) => {
    e.stopPropagation()
    let value = null
    if (multiple) {
      value = []
    }
    input.onChange && input.onChange(value)
    onChange && onChange(value)
    setName('')
    onChangeName && onChangeName('')
    onChangeItem && onChangeItem(null)
  }

  const handleIconClick = (e) => {
    e.stopPropagation()
    if (!input.value) {
      return
    }
    onIconClick && onIconClick()
  }

  const handleClick = (value, data) => {
    const { name } = data
    setName(name)
    input.onChange && input.onChange(value)
    if (lookupMode) {
      onChange &&
        onChange({
          target: {
            value,
          },
        })
    } else {
      onChange && onChange(value)
    }
    onChangeName && onChangeName(name)
    onChangeItem && onChangeItem({ id: value, name })
  }

  const showClear = () => {
    if (noClear) {
      return false
    }
    if (disabled || readOnly) {
      return false
    }
    if (isLookup) {
      const { value } = input
      if (multiple && value && value.length === 0) {
        return false
      }
      if (!multiple && !emptyValue) {
        return false
      }
      if (!multiple && !value) {
        return false
      }
      return true
    } else {
      return !!name
    }
  }

  let noUndeline = disableUnderline
  if (valueNoUnderline) {
    if (multiple) {
      noUndeline = (input.value || []).length > 0
    } else {
      noUndeline = !!input.value
    }
  }
  return (
    <FormControl
      className={clsx(fullWidth && classes.formControl)}
      required={required}
    >
      {!nolabel && label && (
        <InputLabel shrink htmlFor={input.name}>
          {label}
        </InputLabel>
      )}

      <Input
        id={input.name}
        disabled={disabled}
        disableUnderline={noUndeline}
        classes={{ input: (!disabled || hyperlink) && classes.input }}
        type="text"
        readOnly={true}
        value={name || ''}
        placeholder={placeholder}
        onClick={handleInputClick}
        margin={margin}
        inputProps={inputProps}
        startAdornment={
          icon && (
            <InputAdornment
              position="start"
              onClick={handleIconClick}
              className={classes.input}
            >
              {icon}
            </InputAdornment>
          )
        }
        endAdornment={
          !disabled && (
            <InputAdornment position="end">
              <Tooltip
                title={t('common.form.searchField.button.search.tooltip')}
              >
                <IconButton onClick={handleSearchClick} edge="end">
                  <MoreHorizIcon />
                </IconButton>
              </Tooltip>
              {showClear() && (
                <Tooltip
                  title={t('common.form.searchField.button.clear.tooltip')}
                >
                  <IconButton onClick={handleClearClick} edge="end">
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          )
        }
      />
      <FormHelperText error>{!active && dirty && error}</FormHelperText>
    </FormControl>
  )
}

export default SearchField
