import * as types from './types'
import { createAction } from 'Utils/redux'

export const checkHtml = createAction(types.WORKFLOW_CHECK_HTML, 'payload')

export const createWorkflow = createAction(
  types.WORKFLOW_CREATE_WORKFLOW,
  'payload'
)
export const deleteWorkflow = createAction(
  types.WORKFLOW_DELETE_WORKFLOW,
  'id',
  'name'
)
export const getActions = createAction(types.WORKFLOW_GET_ACTIONS, 'id')
export const getWorkflow = createAction(types.WORKFLOW_GET_WORKFLOW, 'payload')
export const getWorkflowNumber = createAction(
  types.WORKFLOW_GET_WORKFLOW_NUMBER
)
export const getWorkflowNumberCompleted = createAction(
  types.WORKFLOW_GET_WORKFLOW_NUMBER_COMPLETED,
  'data'
)
export const updateWorkflow = createAction(
  types.WORKFLOW_UPDATE_WORKFLOW,
  'payload'
)
export const loadList = createAction(types.WORKFLOW_LOAD_LIST)
export const loadListManual = createAction(
  types.WORKFLOW_LOAD_LIST_MANUAL,
  'entity',
  'id'
)
export const loadListCompleted = createAction(
  types.WORKFLOW_LOAD_LIST_COMPLETED,
  'data'
)
export const setFilter = createAction(types.WORKFLOW_SET_FILTER, 'filter')
export const setFilterContext = createAction(
  types.WORKFLOW_SET_FILTER_CONTEXT,
  'filter'
)
export const setLoading = createAction(types.WORKFLOW_SET_LOADING, 'value')
export const startWorkflow = createAction(
  types.WORKFLOW_START_WORKFLOW,
  'payload'
)
